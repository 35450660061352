
import { Vinyl } from '@/api/api'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    vinyl: Object as () => Vinyl
  }
})
export default class VinylComponent extends Vue {
    vinyl!: Vinyl
}
