
import { getVinylWishlist, Vinyl } from '@/api/api'
import { Options, Vue } from 'vue-class-component'
import VinylComponent from '@/components/VinylComponent.vue'

@Options({
  components: {
    VinylComponent
  }
})
export default class VinylWishlist extends Vue {
  vinyls: Vinyl[] = []
  loading = false

  mounted() {
    this.loading = true
    getVinylWishlist()
      .then((vinyls: Vinyl[]) => {
        this.vinyls = vinyls
        this.loading = false
      })
      .catch((err) => console.log(err))
  }
}
